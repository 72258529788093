import React from 'react'
import { Link } from 'gatsby'

import { logEvent } from '../utils/analytics'

const LinkClass =
  'bg-transparent bn link dim dib relative w-100 w-auto-l inherit pa2 pv0-l ph0 ph2-l tl tc-l pointer'

const Header = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      peopleDropdownOpen: false,
      workDropdownOpen: false,
      menuOpen: false,
      isHome: true,
      isTop: true
    }

    this.togglePeopleDropdown = this.togglePeopleDropdown.bind(this)
    this.toggleWorkDropdown = this.toggleWorkDropdown.bind(this)
    this.isTopChecker = this.isTopChecker.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.logClick = this.logClick.bind(this)
  }

  togglePeopleDropdown() {
    if (this.state.workDropdownOpen) {
      this.toggleWorkDropdown()
    }
    this.setState({
      peopleDropdownOpen: !this.state.peopleDropdownOpen
    })
  }

  toggleWorkDropdown() {
    if (this.state.peopleDropdownOpen) {
      this.togglePeopleDropdown()
    }
    this.setState({
      workDropdownOpen: !this.state.workDropdownOpen
    })
  }

  isTopChecker() {
    const isTop = window.scrollY < 25
    if (isTop !== this.state.isTop) {
      this.setState({ isTop })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.isTopChecker)
    if (typeof window !== 'undefined' && window.location.pathname === '/') {
      this.setState({
        isHome: true
      })
    } else {
      this.setState({
        isHome: false
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.isTopChecker)
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  closeMenu() {
    if (this.state.menuOpen) {
      this.setState({
        menuOpen: false
      })
    }
  }

  logClick(action) {
    const event = {
      category: 'Get Started',
      action: `Clicked ${action}`
    }
    logEvent(event)
  }

  getToLink(object, index, type) {
    if (index === 'all') {
      if (type === 'lawyers') {
        return '/lawyers'
      } else if (type === 'work') {
        return '/work'
      }
    } else {
      if (type === 'lawyers' && object.node.slug) {
        if (object.node.slug.current === 'articling-students') {
          return '/students'
        } else {
          return `/lawyers/${object.node.slug.current}`
        }
      } else if (type === 'work' && object.node.slug) {
        return `/work/${object.node.slug.current}`
      }
    }
  }

  render() {
    const { email, people, work } = this.props

    const FirstAndLast = ({ name, last }) => (
      <>
        {name} {last}
      </>
    )

    const ListItem = ({ object, index, type }) => (
      <li className="dib relative w-100 pa0 ma0">
        <Link
          className="dib relative w-100 inherit link dim f6 tl ph0 pv2 pa3-l"
          onClick={this.closeMenu}
          to={this.getToLink(object, index, type)}
        >
          {object.node.name ? (
            <FirstAndLast name={object.node.name} last={object.node.lastname} />
          ) : null}
          {object.node.title ? object.node.title : null}
        </Link>
      </li>
    )

    const Logo = () => (
      <Link className="dib relative link dim logo tc z-1" title="Friedmans" to="/">
        <img
          className="dib relative h-100 w-100"
          alt="Friedmans"
          src={
            this.state.isTop && !this.state.menuOpen && this.state.isHome
              ? '/img/logo-white.svg'
              : '/img/logo.svg'
          }
        />
      </Link>
    )

    const MiddleNav = () => (
      <div className="dib relative w-100 tl tc-l f3 f4-l">
        <Link className={`${LinkClass} dn-l`} onClick={this.closeMenu} to="/">
          Home
        </Link>
        <Link className={LinkClass} onClick={this.closeMenu} to="/about">
          About
        </Link>
        <div
          className={`dib relative w-100 w-auto-l dropdown-container ${
            this.state.peopleDropdownOpen ? 'open' : 'closed'
          }`}
        >
          <button className={LinkClass} onClick={this.togglePeopleDropdown}>
            Lawyers{' '}
            <img
              className={`dib relative v-mid dropdown-icon ${
                this.state.peopleDropdownOpen ? 'invert' : ''
              }`}
              alt="Dropdown"
              src={
                this.state.isTop &&
                !this.state.menuOpen &&
                typeof window !== 'undefined' &&
                window.location.pathname === '/'
                  ? '/img/dropdown-white.svg'
                  : '/img/dropdown.svg'
              }
            />
          </button>
          <div className="relative absolute-l left-0 top-0 bg-white black dropdown-list mb3 mb0-l br2">
            <ul className="list pa0 ma0">
              <ListItem
                object={{
                  node: {
                    name: 'See all →'
                  }
                }}
                index={'all'}
                type="lawyers"
                key={`lawyers-last`}
              />
              {people.edges.map((object, index) => (
                <ListItem object={object} index={index} type="lawyers" key={`people-${index}`} />
              ))}
            </ul>
          </div>
        </div>
        <div
          className={`dib relative w-100 w-auto-l dropdown-container ${
            this.state.workDropdownOpen ? 'open' : 'closed'
          }`}
        >
          <button className={LinkClass} onClick={this.toggleWorkDropdown}>
            Work{' '}
            <img
              className={`dib relative v-mid dropdown-icon ${
                this.state.workDropdownOpen ? 'invert' : ''
              }`}
              alt="Dropdown"
              src={
                this.state.isTop &&
                !this.state.menuOpen &&
                typeof window !== 'undefined' &&
                window.location.pathname === '/'
                  ? '/img/dropdown-white.svg'
                  : '/img/dropdown.svg'
              }
            />
          </button>
          <div className="relative absolute-l left-0 top-0 bg-white black dropdown-list mb3 mb0-l br2">
            <ul className="list pa0 ma0">
              <ListItem
                object={{
                  node: {
                    name: 'See all →'
                  }
                }}
                index={'all'}
                type="work"
                key={`work-last`}
              />
              {work.edges.map((object, index) => (
                <ListItem object={object} index={index} type="work" key={`work-${index}`} />
              ))}
            </ul>
          </div>
        </div>
        <Link className={LinkClass} onClick={this.closeMenu} to="/insights">
          Insights
        </Link>
      </div>
    )

    const RightNav = () => (
      <div className="dib relative w-100 w-auto-l">
        {/* Talk to us */}
        <a
          className="dib relative w-100 w-auto-l bg-brand-blue white underline tc ph3 pv2 br2 b link dim pointer"
          onClick={() => {
            this.logClick('Contact Us')
          }}
          href={`mailto:${email}`}
        >
          Contact us
        </a>
      </div>
    )

    const CloseButton = () => (
      <button
        className={`bg-transparent bn link dim dib dn-l relative w-100 w-auto-l inherit`}
        onClick={this.closeMenu}
      >
        Close
      </button>
    )

    const MenuButton = () => (
      <button className="dib dn-l relative bn bg-transparent w-auto" onClick={this.toggleMenu}>
        Menu
      </button>
    )

    return (
      <nav
        aria-label="main-navigation"
        className={`navbar w-100 fixed left-0 top-0 f4 z-2 ph4 ${
          !this.state.isTop ? 'bg-white pv2 black' : 'pv4 white'
        }`}
        role="navigation"
      >
        {/* Mobile nav */}
        <div
          className={`dib bg-white fixed overflow-y-scroll left-0 top-0 z-3 w-100 h-100 mobile pa4 tl black ${
            this.state.menuOpen ? 'open' : ''
          }`}
        >
          <div className="flex w-100 justify-between items-center">
            <Logo />
            <div className="dib dn-l relative tr">
              <CloseButton />
            </div>
          </div>
          <div className="dib relative w-100 mv4">
            <MiddleNav />
          </div>
          <div className="dib relative w-100 mb4">
            <RightNav />
          </div>
        </div>
        {/* Non-mobile nav */}
        <div
          className={`db center mw8 ph0 ph4-l ${
            typeof window !== 'undefined' && window.location.pathname !== '/' ? 'black' : ''
          }`}
        >
          <div className="flex w-100 justify-between items-center">
            {/* Left */}
            <Logo />
            {/* Middle */}
            <div className="dn dib-l relative v-mid tc middle">
              <MiddleNav />
            </div>
            {/* Right */}
            <div className="dn dib-l relative tr">
              <RightNav />
            </div>
            {/* Menu */}
            <div className="dib dn-l relative tr">
              <MenuButton />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
