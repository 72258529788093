import React from 'react'

import { initGA, logPageView } from '../utils/analytics'

import Container from './container'
import Header from './header'
import Footer from './footer'

import 'flexboxgrid'
import 'tachyons'

import '../styles/all.sass'

class Layout extends React.Component {
  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }
    logPageView()
  }

  render() {
    const { children, companyInfo, people, siteTitle, work } = this.props

    return (
      <>
        <Header siteTitle={siteTitle} email={companyInfo.email} people={people} work={work} />
        <Container>{children}</Container>
        <Footer
          addressLineTwo={companyInfo.address2}
          address={companyInfo.address1}
          country={companyInfo.country}
          email={companyInfo.email}
          name={companyInfo.name}
          city={companyInfo.city}
          zip={companyInfo.zipCode}
          tel={companyInfo.tel}
        />
      </>
    )
  }
}

export default Layout
