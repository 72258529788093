import React from 'react'
// import { Link } from 'gatsby'

import { logEvent } from '../utils/analytics'

const footerLinks = 'dib relative w-100 mb2 link dim inherit b'
const year = new Date().getFullYear()

const Footer = class extends React.Component {
  constructor(props) {
    super(props)

    this.logClick = this.logClick.bind(this)
  }

  logClick(name) {
    const event = {
      category: 'Social',
      action: `Clicked ${name}`
    }
    logEvent(event)
  }

  render() {
    const { addressLineTwo, address, email, name, city, tel, zip } = this.props
    return (
      <footer className="dib relative w-100 footer f5 black tl">
        <div className="db center mw8 pa4">
          <div className="dib flex-l justify-between items-top w-100">
            {/* Logo */}
            <div className="dib relative w-auto">
              <img className="dib relative v-top w4 mb2" alt={name} src="/img/logotype.svg" />
              <small className="dib relative w-100 gray mb4">
                &copy; {year} {name}
              </small>
            </div>
            {/* Call */}
            <div className="dib relative w-100 w-auto-l mb2 mb0-l">
              <a className={footerLinks} href={`tel:${tel}`}>
                Call us
              </a>
              <p className="inherit fw5 f6 mb4 mh0 mv0-l">
                {address}
                <br />
                {addressLineTwo}
                <br />
                {city}, {zip}
                <br />
                {tel}
              </p>
            </div>
            {/* Email */}
            <div className="dib relative w-100 w-auto-l tl tr-l">
              <a className={footerLinks} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
